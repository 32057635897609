.App {
  background-color: hsla(242, 88.4%, 45.3%, 1);
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Custom Styles */
.font-playfair {
  font-family: "Helvetica", "Arial", sans-serif;
}
.rounded-lg {
  border-radius: 3.5rem !important;
}
.shadowtext {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5) !important;
}

.img-reaper {
  height: 600px;
  width: auto;
}
.img-anim {
  height: 350px;
  width: auto;
}
@media (max-width: 768px) {
  .img-reaper {
    height: auto; /* Adjust for mobile, letting the image scale */
    width: 100%; /* Or adjust the percentage based on your design */
  }
  .img-anim {
    height: 300px; /* Adjusted height for mobile */
    width: auto;
  }
}
